<!--
 * @Description: 维护费用管理
 * @Author: ChenXueLin
 * @Date: 2021-10-15 15:13:12
 * @LastEditTime: 2022-08-25 15:42:25
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="taskItemId">
              <el-input
                v-model="searchForm.taskItemId"
                placeholder="工单ID"
                title="工单ID"
                maxlength="10"
                @input="
                  searchForm.taskItemId = searchForm.taskItemId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="companyId">
              <e6-vr-select
                v-model="searchForm.companyId"
                :data="corpList"
                placeholder="公司名称"
                title="公司名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="salesmanName">
              <el-input
                v-model="searchForm.salesmanName"
                placeholder="业务员"
                title="业务员"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="schTaxId">
              <el-input
                v-model="searchForm.schTaxId"
                placeholder="费用ID"
                title="费用ID"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="feeType">
              <e6-vr-select
                v-model="searchForm.feeType"
                :data="feeTypeList"
                placeholder="费用类型"
                title="费用类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="payeeName">
              <el-input
                v-model="searchForm.payeeName"
                placeholder="收款人"
                title="收款人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taxGiveType">
              <e6-vr-select
                v-model="searchForm.taxGiveType"
                :data="taxGiveTypeList"
                placeholder="收费方式"
                title="收费方式"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="confirmStatus">
              <e6-vr-select
                v-model="searchForm.confirmStatus"
                :data="confirmStatusList"
                placeholder="费用确认状态"
                title="费用确认状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createUserName">
              <el-input
                v-model="searchForm.createUserName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i
            class="e6-icon-clear_line"
            title="删除费用条目"
            @click="deleteFee"
          ></i>
          <i
            class="e6-icon-check_line1"
            title="费用确认"
            @click="confirmFee"
          ></i>
          <i class="e6-icon-export_fill" title="导出" @click="exportData"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          :row-key="
            row => {
              return row.schTaxId;
            }
          "
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="150"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <el-dialog
        v-dialogDrag
        title="编辑费用条目"
        :visible.sync="editFeeVisible"
        width="900px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        v-loading="editFeeLoading"
        :element-loading-background="loadingBackground"
        custom-class="custom-dialog"
      >
        <el-form
          ref="editFeeForm"
          label-width="88px"
          :model="editFeeForm"
          :rules="editFeeFormRules"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="客户名称">
                {{ editFeeForm.companyName }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="场景">
                {{ editFeeForm.sceneName }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="场景类型">
                {{ editFeeForm.sceneName }}
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="实收价格" prop="recMoney">
                <el-input
                  placeholder="请输入实收价格"
                  v-model="editFeeForm.recMoney"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收费方式" prop="taxGiveType">
                <e6-vr-select
                  v-model="editFeeForm.taxGiveType"
                  :data="modifyTaxGiveTypeList"
                  placeholder="收费方式"
                  title="收费方式"
                  clearable
                  :props="{
                    id: 'codeValue',
                    label: 'codeName'
                  }"
                ></e6-vr-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button class="cancel" @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleUpdate">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { exportData } from "@/utils/download";
import {
  getMaintainFeeList,
  findDownList,
  feeDelete,
  updateFee,
  exportMaintainFeeList,
  confirmByTaxIds
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "feeManage",
  components: {},
  data() {
    return {
      total: 0,
      loading: false,
      editFeeVisible: false,
      currencyList: [], //币种下拉框
      confirmStatusList: [], //条目状态下拉框
      feeTypeList: [], //费用状态
      corpList: [], //公司下拉框
      taxGiveTypeList: [], //收费方式
      modifyTaxGiveTypeList: [], //编辑时收费方式
      searchForm: {
        companyId: "",
        confirmStatus: "",
        feeType: "",
        createTime: [],
        payeeName: "",
        salesmanName: "",
        sceneName: "",
        schTaxId: "",
        taskItemId: "",
        taxGiveType: "",
        endCreateTime: "",
        startCreateTime: "",
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "taskItemId",
          display: true,
          fieldLabel: "工单ID",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "companyName",
          display: true,
          fieldLabel: "公司名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "salesmanName",
          display: true,
          fieldLabel: "业务员",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "vehicleNo",
          display: true,
          fieldLabel: "车牌号码（真实车牌）",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "regName",
          display: true,
          fieldLabel: "客服标记的车牌（系统中使用的车牌）",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "schTaxId",
          display: true,
          fieldLabel: "费用ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeTypeName",
          display: true,
          fieldLabel: "费用类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeName",
          display: true,
          fieldLabel: "费用条目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "receivable",
          display: true,
          fieldLabel: "应收价格",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "paidIn",
          display: true,
          fieldLabel: "实收价格",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "currencyName",
          display: true,
          fieldLabel: "币种",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "payeeName",
          display: true,
          fieldLabel: "收款人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taxGiveTypeName",
          display: true,
          fieldLabel: "收款方式",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "confirmStatusName",
          display: true,
          fieldLabel: "费用确认状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "checkerName",
          display: true,
          fieldLabel: "审核人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "checkTime",
          display: true,
          fieldLabel: "审核时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createUserName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: [],
      editFeeLoading: false,
      editFeeForm: {
        recMoney: "",
        taxGiveType: ""
      },
      editFeeFormRules: {
        recMoney: [
          {
            required: true,
            message: "请输入金额",
            trigger: ["blur", "change"]
          }
        ],
        taxGiveType: [
          {
            required: true,
            message: "请选择收费方式",
            trigger: ["blur", "change"]
          }
        ]
      },
      refTable: "elTable",
      queryListAPI: getMaintainFeeList
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startCreateTime = val[0] || "";
        this.searchForm.endCreateTime = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    handleInput(val) {
      let data = String(val);
      const char = data.charAt(0);
      // 先把非数字的都替换掉
      data = data.replace(/[^\d]/g, "");
      // 如果第一位是负号，则允许添加
      if (char === "-") {
        data = "-" + data;
      }
      this.searchForm.itemId = data;
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.companyId = "";
        this.loadCorpData(val);
      }
    }, 300),
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList([
            "currency",
            "feeType",
            "confirmStatus",
            "taxGiveType",
            "modifyTaxGiveType"
          ])
        ];
        let [currencyRes] = await Promise.all(promiseList);
        //币种下拉框
        this.currencyList = this.getFreezeResponse(currencyRes, {
          path: "data.currency"
        });
        //费用状态下拉框
        this.feeTypeList = this.getFreezeResponse(currencyRes, {
          path: "data.feeType"
        });
        //条目状态下拉框
        this.confirmStatusList = this.getFreezeResponse(currencyRes, {
          path: "data.confirmStatus"
        });
        //收费方式
        this.taxGiveTypeList = this.getFreezeResponse(currencyRes, {
          path: "data.taxGiveType"
        });
        //编辑时收费方式
        this.modifyTaxGiveTypeList = this.getFreezeResponse(currencyRes, {
          path: "data.modifyTaxGiveType"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.schTaxId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //  导出
    exportData() {
      exportData(this, exportMaintainFeeList);
    },
    //删除费用
    deleteFee() {
      if (!this.selColumnId.length) {
        this.$message.warning("请先勾选要删除的数据");
        return;
      }
      this.$confirm("是否确认删除选中费用？", "删除费用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteFeeReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除请求
    async deleteFeeReq() {
      try {
        this.loading = true;
        let res = await feeDelete({ schTaxIdList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    async confirmFee() {
      if (!this.selColumnId.length) {
        this.$message.warning("请先勾选要删除的数据");
        return;
      }
      try {
        this.loading = true;
        let res = await confirmByTaxIds({ schTaxIdList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.editFeeForm = {
        recMoney: "",
        taxGiveType: ""
      };
      this.$refs.editFeeForm.resetFields();
      this.editFeeVisible = false;
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑费用",
          id: 1,
          disabled: row.confirmStatus == 3
        },
        {
          label: "任务项详情",
          id: 2
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.editFeeVisible = true;
        this.editFeeForm.companyName = _.cloneDeep(row.companyName);
        this.editFeeForm.sceneName = _.cloneDeep(row.sceneName);
        this.editFeeForm.recMoney = _.cloneDeep(row.paidIn);
        this.editFeeForm.schTaxId = _.cloneDeep(row.schTaxId);
        this.editFeeForm.versionNumber = _.cloneDeep(row.versionNumber);
        this.editFeeForm.taxGiveType = _.cloneDeep(row.taxGiveType.toString());
      }
      if (val.id == 2) {
        this.routerPush({
          name: "taskListManage/ActionItemDetail",
          params: {
            refresh: true,
            itemId: row.taskItemId
          }
        });
      }
    },
    handleUpdate() {
      this.$refs.editFeeForm.validate(valid => {
        if (valid) {
          this.updateFeeReq();
        }
      });
    },
    async updateFeeReq() {
      try {
        this.loading = true;
        let res = await updateFee({ schTaxList: [this.editFeeForm] });
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 220px;
    }
  }
}
</style>
